'use client';

import {AppCrash} from "@/ui/AppCrash/AppCrash";

export default function Error({
                                  error,
                                  reset,
                              }: {
    error: Error & { digest?: string };
    reset: () => void;
}) {
    return (
        <AppCrash/>
    );
}